import {Button, Card, Col, Form, Input, message} from 'antd';
import * as React from 'react';
import {useState} from 'react';
// @ts-ignore
import CriblIcon from '../../static/svgs/criblCloud.white.svg';
import {navigate} from 'gatsby';
import {MainLayout} from '../Layout/MainLayout';
import Paragraph from 'antd/lib/typography/Paragraph';
import {forgot} from '../../services/API';

const emailRegexp = new RegExp(/^[a-zA-Z0-9.'+_-`{|}~-]+@[a-zA-Z0-9-_]+(?:.[a-zA-Z0-9-]+)*$/);

export const Reset = ({email}: {email: string}) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [showEmail, setShowEmail] = useState(true);
  const [verification, setVerification] = useState('');
  const [password, setPassword] = useState('');

  React.useEffect(() => {
    if (email && emailRegexp.test(email)) {
      setShowEmail(false);
      setEmailAddress(email);
    }
  }, [email]);

  const forgotConfirm = async () => {
    const url = `${process.env.GATSBY_API_URL}/api/forgot/confirm`;
    const rawResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({emailAddress, verification, password}),
      credentials: 'include'
    });
    const response = await rawResponse.json();
    if (rawResponse.status === 200) {
      message.success('Successfully reset password');
      navigate('/');
    } else {
      message.error(response?.message);
    }
  };

  const layout = {};

  return (
    <MainLayout>
      <Card className={'main-card'}>
        <div style={{textAlign: 'center', marginTop: '5em', marginBottom: '1em'}}>
          <CriblIcon />
        </div>
        <Form {...layout} className={'main-form-section'}>
          <Col offset={4} span={16} style={{textAlign: 'center'}}>
            {!showEmail && <Paragraph>For your security a verification code has been sent to {emailAddress}</Paragraph>}
            {showEmail && (
              <Form.Item name='email' rules={[{required: true, message: 'Please input your email!'}]}>
                <Input onChange={(e) => setEmailAddress(e.target.value)} placeholder={'Enter Email'} />
              </Form.Item>
            )}
          </Col>
          <Col offset={4} span={16} style={{textAlign: 'center'}}>
            <Form.Item name='password' rules={[{required: true, message: 'Please input your password!'}]}>
              <Input.Password onChange={(e) => setPassword(e.target.value)} placeholder={'Enter New Password'} />
            </Form.Item>
          </Col>
          <Col offset={4} span={16} style={{textAlign: 'center'}}>
            <Form.Item name='verification' rules={[{required: true, message: 'Please input your verification code!'}]}>
              <Input onChange={(e) => setVerification(e.target.value)} placeholder={'Enter Verification Code'} />
            </Form.Item>
          </Col>
          <Col span={16} offset={4} style={{textAlign: 'center'}}>
            <Button
              onClick={() => forgotConfirm()}
              type='primary'
              htmlType='submit'
              block={true}
              className={'orange-button'}
            >
              Reset Password
            </Button>
          </Col>
          <Col span={16} offset={4} style={{textAlign: 'center'}}>
            <Paragraph style={{marginTop: '1em'}}>
              It may take a minute to receive your code. <strong>Haven't received it yet?</strong>{' '}
              <a onClick={() => forgot(emailAddress)}>Resend a new code</a>
            </Paragraph>
          </Col>
        </Form>
      </Card>
    </MainLayout>
  );
};
