import * as React from 'react';
import '../styles/App.less';
import {Reset} from '../components/Reset/Reset';
import {Helmet} from 'react-helmet';
import {PageProps} from 'gatsby';

// markup
const IndexPage = (props: PageProps) => {
  const params = new URLSearchParams(props.location?.search);
  const email = params.get('email');

  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet='utf-8' />
        <title>Cribl.Cloud - Reset Password</title>
        <link rel='canonical' href='https://portal.cribl.cloud/reset' />
      </Helmet>
      <main key={'reset'} className={'main-holder'}>
        <Reset email={email} />
      </main>
    </>
  );
};

export default IndexPage;
